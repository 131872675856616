




















import { computed, defineComponent } from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
// import { useConfig } from '~/composables';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  name: 'HeaderLogo',
  components: { SvgImage },
  setup() {
    // const { config } = useConfig();

    const logoSrc = computed(
      () => addBasePath('uni-logo.svg')
      // const baseMediaUrl = config.value.base_media_url;
      // const logo = config.value.header_logo_src;

      // return baseMediaUrl && logo ? `${baseMediaUrl}logo/${logo}` : '';
    );

    // const logoWidth = computed(() => config.value.logo_width || '22');

    // const logoHeight = computed(() => config.value.logo_height || '18');

    const logoAlt = computed(() => 'UNINEED');
    const handleToHome = () => {
      if (window) {
        window.location.href = '/';
      }
    };
    return {
      logoAlt,
      // logoHeight,
      logoSrc,
      // logoWidth,
      addBasePath,
      handleToHome,
    };
  },
});
